<template>
    <div>
        <v-container fluid>
            <v-card>
                <!--Usd Quotes-->
                <v-row no-gutters>
                    <v-col cols="12" xs="12" sm="12" md="6">
                        <div
                            style="max-height: 600px; overflow-y: auto;"
                            class="mt-5 mx-3"
                        >
                            <v-data-table
                                height="44vh"
                                :headers="quotesByAgesHeaders"
                                :items="filteredQuotesByAgesUsd"
                                :sort-by="'total'"
                                :sort-desc="true"
                                disable-pagination
                                hide-default-footer
                                :mobile-breakpoint="0"
                                fixed-header
                            >
                                <template v-slot:top>
                                    <v-toolbar
                                        flat
                                        no-gutters
                                        style="background-color: #eeeeee"
                                        :class="
                                            $vuetify.breakpoint.xs
                                                ? 'd-flex flex-column align-items-center py-4'
                                                : 'd-flex justify-between align-items-center py-4'
                                        "
                                    >
                                        <v-toolbar-title
                                            >Quotes by Ages
                                            (USD)</v-toolbar-title
                                        >
                                    </v-toolbar>
                                </template>
                                <template v-slot:header.name>
                                    <v-text-field
                                        v-model="usdSearch"
                                        label="Clients"
                                        append-icon="mdi-magnify"
                                        hide-details
                                        single-line
                                        dense
                                    />
                                </template>
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td style="width: 150px;">
                                            {{ item.name }}
                                        </td>
                                        <td
                                            v-for="(range,
                                            index) in quotesByAgesUSD[item.name]
                                                .ranges"
                                            :key="index"
                                            @click="
                                                openUsdRangeDialog(
                                                    item.name,
                                                    `${formatDate(
                                                        range.startDate
                                                    )} - ${formatDate(
                                                        range.endDate
                                                    )}`,
                                                    `${range.from} days - ${range.to} days`
                                                )
                                            "
                                        >
                                            {{
                                                formatUsd(
                                                    getTotalCosts(
                                                        range.usdQuotes
                                                    )
                                                )
                                            }}
                                        </td>
                                        <td>{{ formatUsd(item.total) }}</td>
                                    </tr>
                                </template>
                                <template v-slot:[`body.append`]>
                                    <tr style="background-color: #eeeeee;">
                                        <td><strong>Total</strong></td>
                                        <td
                                            v-for="(header,
                                            index) in quotesByAgesHeaders.slice(
                                                1,
                                                -1
                                            )"
                                            :key="index"
                                        >
                                            {{
                                                formatUsd(
                                                    getTotalsByRangeUsd(
                                                        header.text
                                                    )
                                                )
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                formatUsd(
                                                    getFilteredTotalCostsUsd(
                                                        'total'
                                                    )
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </div>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="6">
                        <div class="mt-5 mx-3">
                            <highcharts
                                :options="chartUsdQuotesByAges"
                            ></highcharts>
                        </div>
                    </v-col>
                </v-row>
                <!--Cop Quotes-->
                <v-row no-gutters>
                    <v-col cols="12" xs="12" sm="12" md="6">
                        <div
                            class="mt-10 mx-3"
                            style="max-height: 600px; overflow-y: auto;"
                        >
                            <v-data-table
                                height="44vh"
                                :headers="quotesByAgesHeaders"
                                :items="filteredQuotesByAgesCop"
                                :sort-by="'total'"
                                :sort-desc="true"
                                disable-pagination
                                hide-default-footer
                                :mobile-breakpoint="0"
                                fixed-header
                            >
                                <template v-slot:top>
                                    <v-toolbar
                                        flat
                                        no-gutters
                                        style="background-color: #eeeeee"
                                        :class="
                                            $vuetify.breakpoint.xs
                                                ? 'd-flex flex-column align-items-center py-4'
                                                : 'd-flex justify-between align-items-center py-4'
                                        "
                                    >
                                        <v-toolbar-title
                                            >Quotes by Ages
                                            (COP)</v-toolbar-title
                                        >
                                    </v-toolbar>
                                </template>
                                <template v-slot:header.name>
                                    <v-text-field
                                        v-model="copSearch"
                                        label="Clients"
                                        append-icon="mdi-magnify"
                                        hide-details
                                        single-line
                                        dense
                                    />
                                </template>
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td style="width: 150px;">
                                            {{ item.name }}
                                        </td>
                                        <td
                                            v-for="(range,
                                            index) in quotesByAgesCOP[item.name]
                                                .ranges"
                                            :key="index"
                                            @click="
                                                openCopRangeDialog(
                                                    item.name,
                                                    `${formatDate(
                                                        range.startDate
                                                    )} - ${formatDate(
                                                        range.endDate
                                                    )}`,
                                                    `${range.from} days - ${range.to} days`
                                                )
                                            "
                                        >
                                            {{
                                                formatCop(
                                                    getTotalCosts(
                                                        range.copQuotes
                                                    )
                                                )
                                            }}
                                        </td>
                                        <td>{{ formatCop(item.total) }}</td>
                                    </tr>
                                </template>
                                <template v-slot:[`body.append`]>
                                    <tr style="background-color: #eeeeee;">
                                        <td><strong>Total</strong></td>
                                        <td
                                            v-for="(header,
                                            index) in quotesByAgesHeaders.slice(
                                                1,
                                                -1
                                            )"
                                            :key="index"
                                        >
                                            {{
                                                formatCop(
                                                    getTotalsByRangeCop(
                                                        header.text
                                                    )
                                                )
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                formatCop(
                                                    getFilteredTotalCostsCop(
                                                        'total'
                                                    )
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </div>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="6">
                        <div style="margin-top: 35px;">
                            <highcharts
                                :options="chartCopQuotesByAges"
                            ></highcharts>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>

        <!-- Quotes Dialog -->
        <v-dialog
            v-model="quotesDialog"
            max-width="1500px"
            :retain-focus="false"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ headerRange }}
                </v-card-title>
                <v-card-text>
                    <p>{{ formattedRange }}</p>
                    <div style="max-height: 400px; overflow-y: auto;">
                        <v-data-table
                            :headers="headers"
                            :items="quotesForSelectedRange || []"
                            id="virtual-scroll-table"
                            disable-pagination
                            hide-default-footer
                            :mobile-breakpoint="0"
                            class="mt-2"
                        >
                            <template v-slot:top>
                                <v-toolbar
                                    flat
                                    style="background-color: #eeeeee !important"
                                >
                                    <v-toolbar-title>QUOTES</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                            </template>

                            <template v-slot:[`item.userId`]="{ item }">
                                <div class="my-4">
                                    <p
                                        v-for="(accountManager,
                                        index) of item.collaborators"
                                        :key="index"
                                        class="my-0 mx-0"
                                    >
                                        {{ getUserName(accountManager) }}
                                    </p>
                                </div>
                            </template>

                            <template v-slot:[`item.collaborators`]="{ item }">
                                <div class="my-4">
                                    <p
                                        v-for="(collaborator,
                                        index) of item.collaborators"
                                        :key="index"
                                        class="my-0 mx-0"
                                    >
                                        {{ getUserName(collaborator) }}
                                    </p>
                                </div>
                            </template>

                            <template v-slot:footer>
                                <v-simple-table>
                                    <tfoot class="grey lighten-3">
                                        <tr>
                                            <td class="font-weight-bold">
                                                TOTAL
                                            </td>
                                            <td
                                                class="font-weight-bold text-right"
                                            >
                                                {{
                                                    formatNumber(
                                                        getTotalCosts(
                                                            quotesForSelectedRange
                                                        )
                                                    )
                                                }}
                                            </td>
                                        </tr>
                                    </tfoot>
                                </v-simple-table>
                            </template>
                        </v-data-table>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="closeDialog"
                        class="mt-n3 mb-3"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <Errors />
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { Chart } from 'highcharts-vue'
import API from '@/services/api'
export default {
    name: 'QuotesByAges',
    props: {
        quotes: {
            type: Array,
            default() {
                return []
            },
        },
        users: {
            type: Array,
            required: true,
        },
    },
    components: {
        highcharts: Chart,
        Errors: () => import('@/components/Layout/Errors'),
    },
    data() {
        return {
            height: 0,
            quotesData: [],
            quotesByAges: {},
            quotesCurrency: '',
            quotesDialog: false,
            quotesUsdDialog: false,
            quotesCopDialog: false,
            selectedClientName: null,
            chartUsdQuotesByAges: {},
            chartCopQuotesByAges: {},
            quotesByAgesUSD: {},
            quotesByAgesCOP: {},
            usdSearch: '',
            copSearch: '',
            quoteRanges: [],
            quotesForSelectedRange: {},
            formattedRange: '',
            headerRange: '',
            ranges: [],
            quotesByAgesHeaders: this.generateHeaders(),
            headers: [
                {
                    text: 'ID',
                    value: 'number',
                    align: 'center',
                },
                {
                    text: 'Name',
                    value: 'name',
                    align: 'center',
                },
                {
                    text: 'Client',
                    value: 'client.name',
                    align: 'center',
                },
                {
                    text: 'City',
                    value: 'city',
                    align: 'center',
                },
                {
                    text: 'Currency',
                    value: 'currency',
                    align: 'center',
                },
                {
                    text: 'Price',
                    value: 'totalCosts',
                    align: 'center',
                },
                {
                    text: 'Account Manager',
                    value: 'userId',
                    align: 'center',
                },
                {
                    text: 'Collaborators',
                    value: 'collaborators',
                    align: 'center',
                },
            ],
            settingId: '',
            loading: false,
        }
    },
    computed: {
        ...mapState(['costQuotes']),
        formattedQuotesByAgesUsd() {
            return Object.keys(this.quotesByAgesUSD).map(clientName => {
                const totals = {
                    name: clientName,
                    totalByRange: {},
                    total: 0,
                }

                const ranges = this.quotesByAgesUSD[clientName].ranges

                for (let i = 0; i < ranges.length; i++) {
                    const range = ranges[i]
                    const usdQuotesInRange = range.usdQuotes || []
                    const totalForRange = this.getTotalCosts(usdQuotesInRange)

                    const rangeLabel =
                        this.quotesByAgesHeaders[i + 1]?.text ||
                        `Range ${i + 1}`
                    totals.totalByRange[rangeLabel] = totalForRange

                    totals.total += totalForRange
                }
                return totals
            })
        },
        filteredQuotesByAgesUsd() {
            if (!this.usdSearch) {
                return this.formattedQuotesByAgesUsd
            }
            return this.formattedQuotesByAgesUsd.filter(quote =>
                quote.name.toLowerCase().includes(this.usdSearch.toLowerCase())
            )
        },
        formattedQuotesByAgesCop() {
            return Object.keys(this.quotesByAgesCOP).map(clientName => {
                const totals = {
                    name: clientName,
                    totalByRange: {},
                    total: 0,
                }

                const ranges = this.quotesByAgesCOP[clientName].ranges

                for (let i = 0; i < ranges.length; i++) {
                    const range = ranges[i]
                    const copQuotesInRange = range.copQuotes || []
                    const totalForRange = this.getTotalCosts(copQuotesInRange)

                    const rangeLabel =
                        this.quotesByAgesHeaders[i + 1]?.text ||
                        `Range ${i + 1}`
                    totals.totalByRange[rangeLabel] = totalForRange

                    totals.total += totalForRange
                }
                return totals
            })
        },
        filteredQuotesByAgesCop() {
            if (!this.copSearch) {
                return this.formattedQuotesByAgesCop
            }
            return this.formattedQuotesByAgesCop.filter(quote =>
                quote.name.toLowerCase().includes(this.copSearch.toLowerCase())
            )
        },
    },
    watch: {
        filteredQuotesByAgesUsd() {
            this.createUsdPieChart()
        },
        filteredQuotesByAgesCop() {
            this.createCopPieChart()
        },
        ranges() {
            this.filterByDateRange()
            this.quotesByAgesHeaders = this.generateHeaders()
        },
    },
    async mounted() {
        try {
            this.loading = true
            // get setting id
            const {
                data: { settings },
            } = await API.getSettings()

            const settingIndex = settings.findIndex(u => u.name === 'Quotes')
            if (settingIndex > -1) {
                this.settingId = settings[settingIndex].id
            }

            this.filterByDateRange()
            // get quote ranges
            this.ranges = await this.fetchQuoteRanges()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async fetchQuoteRanges() {
            try {
                this.loading = true
                if (this.settingId) {
                    const response = await API.getQuoteRanges(this.settingId)
                    const quoteRangesArray = Array.isArray(
                        response.quoteRanges[0]
                    )
                        ? response.quoteRanges[0]
                        : response.quoteRanges
                    return quoteRangesArray.quoteRanges
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        generateHeaders() {
            const staticHeaders = [
                { text: 'Clients', value: 'name', sortable: false },
            ]

            const dynamicHeaders = Array.isArray(this.ranges)
                ? this.ranges.map(range => {
                      return {
                          text: `${range.from} - ${range.to} days`,
                          value: `total${range.from}To${range.to}`,
                          sortable: true,
                      }
                  })
                : []

            return [
                ...staticHeaders,
                ...dynamicHeaders,
                { text: 'Total', value: 'total', sortable: true },
            ]
        },
        updateRanges(newRanges) {
            this.ranges = newRanges
            this.quotesByAgesHeaders = this.generateHeaders()
        },
        formatDate(date) {
            return date.toLocaleDateString()
        },
        getTotalsByRangeUsd(rangeLabel) {
            return this.filteredQuotesByAgesUsd.reduce((totalSum, client) => {
                return totalSum + (client.totalByRange[rangeLabel] || 0)
            }, 0)
        },
        getTotalsByRangeCop(rangeLabel) {
            return this.filteredQuotesByAgesCop.reduce((totalSum, client) => {
                return totalSum + (client.totalByRange[rangeLabel] || 0)
            }, 0)
        },
        getTotalCosts(quotes) {
            return quotes.reduce((total, quote) => {
                return total + (quote.totalCosts || 0)
            }, 0)
        },
        getFilteredTotalCostsUsd(key) {
            return this.filteredQuotesByAgesUsd.reduce(
                (sum, client) => sum + client[key],
                0
            )
        },
        getFilteredTotalCostsCop(key) {
            return this.filteredQuotesByAgesCop.reduce(
                (sum, client) => sum + client[key],
                0
            )
        },
        getUserName(id) {
            if (this.users.length > 0) {
                const userReference = this.users.find(user => user.id == id)
                if (userReference) {
                    return userReference.name
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        formatNumber(value) {
            return new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: this.quotesCurrency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(value)
        },
        formatUsd(value) {
            return new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(value)
        },
        formatCop(value) {
            return new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'COP',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(value)
        },
        filterByDateRange() {
            this.quotesByAgesUSD = {}
            this.quotesByAgesCOP = {}
            const currentDate = new Date()
            const allQuotes = Object.values(this.quotes).flat()

            for (let quote of allQuotes) {
                if (quote.sentDate) {
                    if (this.ranges) {
                        const sentDate = new Date(
                            quote.sentDate._seconds * 1000 +
                                quote.sentDate._nanoseconds / 1000000
                        )
                        // set data ranges
                        this.quoteRanges = []
                        const ranges = Object.values(this.ranges)

                        for (let range of ranges) {
                            const startDate = new Date(currentDate)
                            startDate.setDate(currentDate.getDate() - range.to)

                            const endDate = new Date(currentDate)
                            endDate.setDate(currentDate.getDate() - range.from)

                            let quoteRange = {
                                startDate: startDate,
                                endDate: endDate,
                                from: range.from,
                                to: range.to,
                                usdQuotes: [],
                                copQuotes: [],
                            }
                            this.quoteRanges.push(quoteRange)
                        }

                        const clientName = quote.client.name

                        if (!this.quotesByAgesUSD[clientName]) {
                            this.quotesByAgesUSD[clientName] = {
                                ranges: this.quoteRanges.map(range => ({
                                    ...range,
                                    usdQuotes: [],
                                })),
                            }
                        }
                        if (!this.quotesByAgesCOP[clientName]) {
                            this.quotesByAgesCOP[clientName] = {
                                ranges: this.quoteRanges.map(range => ({
                                    ...range,
                                    copQuotes: [],
                                })),
                            }
                        }

                        // sort into range based on currency
                        for (let range of this.quoteRanges) {
                            if (
                                sentDate >= range.startDate &&
                                sentDate <= range.endDate
                            ) {
                                if (quote.currency === 'USD') {
                                    this.quotesByAgesUSD[clientName].ranges
                                        .find(
                                            r =>
                                                r.from === range.from &&
                                                r.to === range.to
                                        )
                                        .usdQuotes.push(quote)
                                } else if (quote.currency === 'COP') {
                                    this.quotesByAgesCOP[clientName].ranges
                                        .find(
                                            r =>
                                                r.from === range.from &&
                                                r.to === range.to
                                        )
                                        .copQuotes.push(quote)
                                }
                            }
                        }
                    }
                }
            }

            // delete clients without quotes in ranges
            this.quotesByAgesUSD = this._filterEmptyClients(
                this.quotesByAgesUSD,
                'usdQuotes'
            )
            this.quotesByAgesCOP = this._filterEmptyClients(
                this.quotesByAgesCOP,
                'copQuotes'
            )

            return {
                usdQuotesByAges: this.quotesByAgesUSD,
                copQuotesByAges: this.quotesByAgesCOP,
            }
        },

        _filterEmptyClients(quotesByAges, quoteType) {
            for (let clientName in quotesByAges) {
                const clientRanges = quotesByAges[clientName].ranges
                const hasQuotes = clientRanges.some(
                    range => range[quoteType].length > 0
                )

                if (!hasQuotes) {
                    delete quotesByAges[clientName]
                }
            }
            return quotesByAges
        },
        // Charts
        createUsdPieChart() {
            const data = this.quotesByAgesHeaders
                .slice(1, -1)
                .map((header, index) => {
                    const total = this.getTotalsByRangeUsd(header.text)
                    return {
                        name: header.text,
                        y: total,
                        color: this.getColorByIndex(index),
                    }
                })
            this.chartUsdQuotesByAges = {
                chart: {
                    type: 'pie',
                },
                title: {
                    text: 'Total quotes by ages (USD)',
                },
                series: [
                    {
                        name: 'Total Costs',
                        colorByPoint: true,
                        data: data,
                    },
                ],
                credits: {
                    enabled: false,
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format:
                                '<b>{point.name}</b>: {point.percentage:.1f} %',
                        },
                    },
                },
            }
        },
        createCopPieChart() {
            const data = this.quotesByAgesHeaders
                .slice(1, -1)
                .map((header, index) => {
                    const total = this.getTotalsByRangeCop(header.text)
                    return {
                        name: header.text,
                        y: total,
                        color: this.getColorByIndex(index),
                    }
                })

            this.chartCopQuotesByAges = {
                chart: {
                    type: 'pie',
                },
                title: {
                    text: 'Total quotes by ages (COP)',
                },
                series: [
                    {
                        name: 'Total Costs',
                        colorByPoint: true,
                        data: data,
                    },
                ],
                credits: {
                    enabled: false,
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format:
                                '<b>{point.name}</b>: {point.percentage:.1f} %',
                        },
                    },
                },
            }
        },
        getColorByIndex(index) {
            const colors = [
                '#23bf85',
                '#efbd21',
                '#f39c12',
                '#db4d42',
                '#3498db',
                '#8e44ad',
                '#2ecc71',
                '#e74c3c',
                '#f1c40f',
                '#e67e22',
                '#1abc9c',
                '#34495e',
            ]
            return colors[index % colors.length]
        },
        // Quotes Dialogs
        openUsdRangeDialog(clientName, formattedRange, headerRange) {
            this.selectedClientName = clientName
            this.headerRange = headerRange
            this.formattedRange = formattedRange
            this.quotesForSelectedRange = this.quotesByAgesUSD[
                clientName
            ].ranges.find(
                r =>
                    r.startDate.toLocaleDateString() +
                        ' - ' +
                        r.endDate.toLocaleDateString() ===
                    formattedRange
            ).usdQuotes
            this.quotesDialog = true
            this.quotesCurrency = 'USD'
        },
        openCopRangeDialog(clientName, formattedRange, headerRange) {
            this.selectedClientName = clientName
            this.headerRange = headerRange
            this.formattedRange = formattedRange
            this.quotesForSelectedRange = this.quotesByAgesCOP[
                clientName
            ].ranges.find(
                r =>
                    r.startDate.toLocaleDateString() +
                        ' - ' +
                        r.endDate.toLocaleDateString() ===
                    formattedRange
            ).copQuotes
            this.quotesDialog = true
            this.quotesCurrency = 'COP'
        },
        closeDialog() {
            this.quotesDialog = false
            this.quotesForSelectedRange = []
            this.quotesCurrency = ''
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead th {
    background-color: #eeeeee !important;
}
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.item_class {
    background-color: #3385d7 !important;
    color: white !important;
}
</style>
